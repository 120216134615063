import React from 'react'
import { FcMoneyTransfer } from "react-icons/fc";
import { BiDotsHorizontalRounded } from "react-icons/bi";

import { formatCurrency } from '../../../../miscellaneous/helpers/utils';

import Cashicon from '../../../../assets/icons/Cash-icon.svg';
import NonCashIcon from '../../../../assets/icons/Non-cash.svg'
import Ellipsis from '../../../../assets/icons/Ellipsis.svg';
import Kaspro from '../../../../assets/icons/Kaspro.svg';

import { t } from '../../../../miscellaneous/language';
// import '../../../Merchant/Merchant.css'
const BottomConfirm = ({ deliveryFee, totalPrice, totalDiscount, platformFee, orderFunction, parkingFee }) => {
    // console.log(formatCurrency(deliveryFee?.data?.result?.Price), 'ini')

    const [loader, setLoader] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [paymentMethod, setPaymentMethod] = React.useState();

    // console.log(modal);

    React.useEffect(() => {
        if (modal) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "unset"
        }
    }, [modal])

    // console.log(modal, 'hi modal');

    return (
        <>
            <div className={modal ? 'bottomNav-modal-container-open' : 'bottomNav-modal-container-close'} />
            <div className='bottomnav-wrapper'>
                <div aria-hidden={!modal} className={modal ? "transitionUp modal-payment-wrapper-open" : "transitionDown modal-payment-wrapper-close"}>

                    <div className='modal-payment-wrapper-header'>
                        <span className="modal-payment-wrapper-header-close" onClick={() => setModal(!modal)}>&times;</span>
                    </div>
                    <div className='modal-payment-wrapper-content-scrollable'>
                        <div className='modal-payment-wrapper-content-scrollable-cash'>
                            <div className='modal-payment-wrapper-content-scrollable-header'>
                                <p>{t.bottomConfirm.payment.title}</p>
                            </div>
                            {/* <div className='modal-payment-wrapper-contents-scrollable-content'>
                                <div className='modal-payment-wrapper-contents-scrollable-content-icon'>
                                    <img src={Kaspro} />
                                </div>
                                <div className='modal-payment-wrapper-contents-scrollable-content-text-wrapper'>
                                    <div className='modal-payment-wrapper-contents-scrollable-content-text'
                                        onClick={() => {
                                            // setModal(!modal)
                                            // setPaymentMethod(1)
                                        }}
                                    >
                                        <span><b>Kaspro</b></span>
                                        <span className='modal-payment-wrapper-contents-scrollable-content-text-bottom'>Pay with kaspro</span>
                                    </div>
                                    <div className='modal-payment-wrapper-contents-scrollable-content-radio'>
                                        <button disabled>Soon</button>
                                    </div>
                                </div>
                            </div>
                            <br /> */}
                            <div className='modal-payment-wrapper-contents-scrollable-content'>
                                <div className='modal-payment-wrapper-contents-scrollable-content-icon'>
                                    <img src={NonCashIcon} />
                                </div>
                                <div className='modal-payment-wrapper-contents-scrollable-content-text-wrapper'>
                                    <div className='modal-payment-wrapper-contents-scrollable-content-text'
                                        onClick={() => {
                                            // setModal(!modal)
                                            // setPaymentMethod(1)
                                        }}
                                    >
                                        <span><b>{t.bottomConfirm.payment.noncash}</b></span>
                                        <span className='modal-payment-wrapper-contents-scrollable-content-text-bottom'>{t.bottomConfirm.payment.paywith} {t.bottomConfirm.payment.noncash}</span>
                                    </div>
                                    <div className='modal-payment-wrapper-contents-scrollable-content-radio'>
                                        <button disabled>{t.bottomConfirm.payment.soon}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />                        
                        <div className='modal-payment-wrapper-content-scrollable-cash'>
                            <div className='modal-payment-wrapper-content-scrollable-header'>
                                <p>{t.bottomConfirm.payment.title2}</p>
                            </div>
                            <div className='modal-payment-wrapper-contents-scrollable-content'>
                                <div className='modal-payment-wrapper-contents-scrollable-content-icon'>
                                    <img src={Cashicon} />
                                </div>
                                <div className='modal-payment-wrapper-contents-scrollable-content-text-wrapper'>
                                    <div className='modal-payment-wrapper-contents-scrollable-content-text'
                                        onClick={() => {
                                            setModal(!modal)
                                            setPaymentMethod(2)
                                        }}
                                    >
                                        <span><b>{t.bottomConfirm.payment.cash}</b></span>
                                        <span className='modal-payment-wrapper-contents-scrollable-content-text-bottom'>{t.bottomConfirm.payment.paywith} {t.bottomConfirm.payment.cash}</span>
                                    </div>
                                    <div className='modal-payment-wrapper-contents-scrollable-content-radio'>
                                        <input type='radio' checked={true} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className='bottomnav-content'>
                    <div className='bottomnav-payment-wrapper' onClick={() => setModal(!modal)}>
                        <div className='bottomnav-payment-wrapper-icon'>
                            <img src={Cashicon} width={30} />
                        </div>
                        <div className='bottomnav-payment-wrapper-name'>
                            <p>{t.bottomConfirm.payment.cash}</p>
                            <p className='bottomnav-payment-wrapper-icon-right'>
                                {
                                    (deliveryFee?.data?.result?.Price != null) ?
                                        <p className='bottomnav-payment-wrapper-text'>{formatCurrency((totalPrice + (deliveryFee?.data?.result?.Price ? deliveryFee?.data?.result?.Price : 0) + platformFee) + parkingFee)}</p>
                                        :
                                        <div className='loader-table'>
                                        </div>
                                }
                            </p>
                        </div>
                        <div className='bottomnav-payment-wrapper-dot'>
                            <img src={Ellipsis} width={30} />
                        </div>
                    </div>
                    <div className='bottomnav-button-wrapper'>
                        <button className='button-confirm' onClick={(e) => { orderFunction() }}>{t.bottomConfirm.btn.confirm}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BottomConfirm