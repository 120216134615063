const En = {
    btn: {
        saveName: 'Guardar nombre',
        yes: 'Sí',
        no: 'No',
        add: 'Añadir',
        viewCart: 'Ver mi carrito',
        alertOk: 'Entendido',
        confirmOrder: 'Confirma su pedido',
        shopping: `¡Vamos de compras!`,
        status: {
            orderStatus0: 'Actualizar el estado del pedido',
            orderStatus1: 'Comprobar el estatus de entrega',
            orderStatus9: 'Pedido a otros comerciantes'
        }
    },
    text: {
        info: 'Nota',
        nonPartnerInfo: 'Este comerciante no es un socio',
        saveName: 'Hola. Antes de continuar, por favor, introduzca su nombre...',
        itemitem: 'Artículos',
        item: 'artículo',
        items: 'artículos',
        empty: 'Vendido',
        orderList: 'Lista de pedidos',
        billDetail: 'Detalle de la factura',
        totalPrice: 'Precio total',
        totalDisc: 'Total descuento',
        platformFee: 'Comisión de plataforma',
        parkingFee: 'Tarifa de estacionamiento',
        deliveryFee: 'Comisión de entrega y manejo',
        takeAwayCharge: "Cargo por llevar",
        unavailable: "No disponible",
        toBePaid: 'A pagar',
        emptyCart: 'Su carrito de compras todavía está vacío :(',
        orderPlaced: {
            title: 'Pedido realizado con éxito',
            desc: 'Esperando a que el comerciante confirme su pedido'
        },
        driverFound: {
            title: 'Conductor encontrado',
            desc: 'Gracias por usar el servicio Maxim'
        },
        driverResto: {
            title: 'conductor en el lugar de venta',
            desc: 'El conductor ha llegado al lugar de venta'
        },
        merchantCancel: {
            title: 'Su pedido está cancelado',
            desc: 'El comerciante canceló su pedido'
        },
        merchantconfirm: {
            title: 'Su pedido está confirmado',
            desc: 'El comerciante confirmó su pedido'
        },
        itemPickedUp: {
            title: 'su pedido está siendo enviado',
            desc: 'Su pedido está en proceso'
        },
        completeStatus: {
            title: 'Su pedido está completo',
            desc: 'Gracias por usar el servicio Maxim'
        },
        userCancel: {
            title: 'Su pedido está cancelado',
            desc: 'El usuario canceló su pedido'
        },
        buyerName: 'Nombre del comprador',
        receiveAt: 'Recibir en',
        status: 'Estatus',
        paymentType: 'Tipo de pago',
        cash: 'En efectivo',
        note: 'Nota: ',
        noNote: 'Sin nota',
        noteMerchant: 'Comentario para el comerciante',
        MerchantDetailDiscountContainer: { recommend: 'Recomendado para usted' },
        AddToCart: 'Añadir al carrito',
        deliverTo: 'Entregar a',
        merchantName: 'Nombre del comerciante',
        deliveryAddress: 'Dirección de entrega',
        phoneNumber: 'Número de teléfono',
        merchantAddress: 'Dirección del comerciante'
    },
    label: { user_name: 'Su nombre: ' },
    placeholder: {
        name: 'Por favor, introduzca su nombre',
        searchHome: 'Búsqueda de comerciante o artículo ...',
        searchCategory: 'Búsqueda de comerciante',
        noteItemDetail: 'Escriba cualquier detalle específico aquí',
        searchItem: 'Buscar artículo...',
        noteMerchant: 'Escriba su comentario o sugerencia para el comerciante'
    },
    alert: {
        orderCancel: 'Este pedido está cancelado',
        nonPartner: 'No es socio',
        partnerPlus: 'El precio del pedido es una estimación y puede variar. Por favor, pida al conductor que le facilite el recibo como referencia de pago del producto.',
        noteNonPartner: 'Este comerciante no es un socio oficial de Maxim, la disponibilidad de los productos y la precisión de los precios pueden cambiar y diferir de las condiciones reales. ¿Continuar?',
        cart: 'Carrito',
        cartEmpty: 'Sólo queda una cantidad de este artículo/menú en su carrito, la disminución de la cantidad significa eliminar artículos de su carrito. ¿Continuar?',
        alert: 'Alerta',
        noteProcessOrder: 'El servidor está procesando su solicitud, por favor, espere... Si el proceso toma demasiado tiempo, por favor, reinicie su aplicación o compruebe su conexión a Internet.',
        confirmOrder: 'Confirmar el pedido',
        noteConfirmOrder: '¿Está seguro de que desea realizar su pedido? Por favor, revise el pedido una vez más antes de confirmarlo.',
        noteCountDeliveryPrice: 'Se ha producido un error al calcular el precio de entrega, por favor, reinicie la aplicación para continuar.',
        noteDeliveryPrice: 'Se ha producido un error al recuperar su ubicación para calcular el precio de entrega, por favor, reinicie la aplicación y asegúrese de que su GPS está prendido para continuar.',
        notePlatformPrice: 'Se ha producido un error al calcular la comisión de la plataforma, por favor, reinicie la aplicación para continuar.',
        noteNonPartnerReceipt: 'Este comerciante no es un socio oficial de Maxim, la disponibilidad de artículos y los precios listados pueden variar. Por favor, realice el pago de acuerdo con el precio indicado en el recibo.',
        merchantClose: '¡El comercio está cerrado!'
    },
    helmet: {
        title: {
            start: 'Foods&Goods - Iniciar',
            Home: 'Foods&Goods',
            Category: 'Foods&Goods - Categoría de comercio',
            History: 'Foods&Goods - Historial',
            DetailCart: 'Foods&Goods - Detalles del carrito',
            afterOrder: 'Foods&Goods - Después del pedido',
            HistoryDetail: 'Foods&Goods - Detalles del historial'
        },
        desc: {
            start: 'Iniciar',
            Home: 'Foods&Goods',
            Category: 'Lista de comerciantes por categoría',
            History: 'Lista de sus transacciones previas',
            DetailCart: 'Detalles de los artículos en su carrito',
            afterOrder: 'Mi pedido',
            HistoryDetail: 'Detalles del historial'
        }
    },
    topNav: { title: 'Foods&Goods' },
    backTopNav: { History: 'Historial' },
    loading: 'Cargando…',
    data: {
        noDataMerchant: 'Comerciante no encontrado',
        DataMerchant: 'Comerciante encontrado',
        notFound: 'No encontrado'
    },
    bottomNav: {
        Home: 'Inicio',
        Explore: 'Explorar',
        Promo: 'Promoción',
        History: 'Historial',
        Cart: 'Carrito'
    },
    status: {
        Open: 'Abrir',
        Close: 'Cerrado',
        case0: 'Esperando la aprobación de la tienda',
        case1: 'Pedido aprobado por la tienda',
        case2: 'Un conductor de maxim entregará el pedido',
        case3: 'Conductor de Maxim asignado al pedido',
        case4: 'El conductor está esperando',
        case11: 'Pedido completado',
        case9: 'Pedido cancelado',
        caseNotFound: 'Estatus del pedido no encontrado',
        orderStatus0: 'Esperando',
        orderStatus1: 'Aprobado por el comerciante',
        orderStatus2: 'Recogido por el Conductor',
        orderStatus3: 'Conductor designado',
        orderStatus4: 'El conductor está esperando',
        orderStatus9: 'Cancelado por el comerciante',
        orderStatus11: 'Completado',
        orderStatus99: 'Cancelado por el usuario',
        orderStatusNotFound: 'Estatus no encontrado',
        itemNotFound: 'No hay artículos en esta categoría'
    },
    receiveAt: { statusAccepted: 'No recibido aún' },
    bottomConfirm: {
        payment: {
            title: 'Método(s) de pago digital',
            title2: 'Otros método(s) de pago',
            cash: 'En efectivo',
            noncash: 'Sin efectivo',
            paywith: 'Pagar con',
            soon: 'Próximamente'
        },
        btn: { confirm: 'Confirmar y proceder al pedido' }
    },
    itemDetails: { note: 'Comentario para el comerciante' },
    addon: {
        optional: 'Opcional',
        qty: 'Cantidad',
        additm: 'Añadir artículo',
        edititm: 'Actualizar artículo',
        max: 'Seleccione max',
        edit: 'Editar',
        require: 'Requiere',
        selectrequire: 'Selección mínima',
        price: 'Precio',
        addcustome: 'Añadir otro cliente',
        free: 'Gratis'
    },
    promo: { listMerchants: 'Lista de vendedores' }
};
export default En;