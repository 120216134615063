import React from 'react'

import './PublicLink.css'
import '../Cart/Cart.css'
import { formatCurrency } from '../../miscellaneous/helpers/utils'
import { t } from '../../miscellaneous/language'


const Receipt = ({ orderData }) => {

    React.useEffect(() => {
        console.log(orderData)
    }, [orderData])

    const dataAddon = [
        { id: 1, name: 'addon item 1', price: 1000 },
        { id: 1, name: 'addon item 2', price: 2000 },
        { id: 1, name: 'addon item 3', price: 3000 },
    ]

    console.log(orderData, 'ini adalah orderData');

    return (
        <div className="receipt-wrapper">
            <div className="receipt-container">
                <div className="receipt-header">
                    <span className="order-code">{orderData?.data?.code}</span>
                </div>
                <div className="receipt-info-container">
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.buyerName}</span>
                        <div className="receipt-client-content orange-color bold">{orderData?.data?.users.name}</div>
                    </div>
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.receiveAt}</span>
                        <div className="receipt-client-content gray-1-color">
                            {
                                orderData?.data?.received_at ? orderData?.data?.received_at : t.receiveAt.statusAccepted
                            }
                        </div>
                    </div>
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.status}</span>
                        <div className="receipt-client-content">{
                            (
                                orderData?.data?.status === 0 ? t.status.orderStatus0
                                    : orderData?.data?.status === 1 ? t.status.orderStatus1
                                        : orderData?.data?.status === 2 ? t.status.orderStatus2
                                            : orderData?.data?.status === 3 ? t.status.orderStatus3
                                                : orderData?.data?.status === 4 ? t.status.orderStatus4
                                                    : orderData?.data?.status === 9 ? t.status.orderStatus9
                                                        : orderData?.data?.status === 11 ? t.status.orderStatus11
                                                            : orderData?.data?.status === 99 ? t.status.orderStatus99
                                                                : t.status.orderStatusNotFound
                            )
                        }</div>
                    </div>
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.paymentType}</span>
                        <div className="receipt-client-content">{t.text.cash}</div>
                    </div>
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.merchantName}</span>
                        <div className="receipt-client-content">{orderData?.data?.merchant?.name}</div>
                    </div>
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.deliveryAddress}</span>
                        <div className="receipt-client-content">{orderData?.data?.users?.addressName}</div>
                    </div>
                    {
                        orderData?.data?.status === 3 || orderData?.data?.status === 4 ?
                            <div className="receipt-info">
                                <span className="receipt-client-title">{t.text.phoneNumber}</span>
                                <div className="receipt-client-content phone-number" onClick={(e) => window.open(`https://wa.me/${orderData?.data?.merchant?.phone}`)}>{orderData?.data?.merchant?.phone}</div>
                            </div>
                            :
                            <></>
                    }
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.merchantAddress}</span>
                        <div className="receipt-client-content">{orderData?.data?.merchant?.address}</div>
                    </div>

                </div>
                <div className="order-items-container">
                    <span className="order-items-text">{t.text.orderList}</span>
                </div>
                <div className="receipt-order-detail-wrapper">
                    {orderData && orderData.data?.order_items?.map((item, index) => (
                        <div className="receipt-order-detail-container">
                            <div className="receipt-order-detail-container-header">
                                <div className="receipt-item-container">
                                    <span className="receipt-item-name">{item.items?.name}</span>
                                    <div className="receipt-item-notes-container">
                                        <span className="receipt-item-notes-title">{t.text.note}</span>
                                        <span className="receipt-item-notes">{item.notes ? item.notes : t.text.noNote}</span>
                                    </div>
                                </div>
                                <div className="receipt-item-counted-container">
                                    <span className="receipt-item-counted">x{item.quantity}</span>
                                </div>
                                <div className="receipt-item-price-container">
                                    <span className="receipt-item-price">{formatCurrency(item.price)}</span>
                                </div>
                            </div>
                            {item?.order_addon_items?.length > 0 ?
                                <div className="receipt-order-detail-container-addon">
                                    <span className='receipt-item-name'>Tambahan:</span>
                                    {
                                        item?.order_addon_items?.map((itm, idx) => (
                                            <div className="receipt-order-detail-container-addon-wrapper">
                                                <span className='receipt-item-name-addon'>{itm?.addon_items?.name}</span>
                                                <span className='receipt-item-price'>{formatCurrency(itm?.price)}</span>
                                            </div>
                                        ))

                                    }
                                </div>
                                :
                                <></>
                            }
                        </div>

                    ))
                    }
                </div>
                <div className="note-to-merchant">
                    <span className="note-to-merchant-title">{t.text.noteMerchant}</span>
                    <span className="note-to-merchant-content">{(orderData?.data?.notes ? orderData?.data?.notes : t.text.noNote)}</span>
                </div>
                <div className="receipt-price-detail-container">
                    <div className="bill-detail">
                        <span>{t.text.billDetail}</span>
                    </div>
                    <div className="bill-detail-content">
                        <span className="gray-1-color">{t.text.totalPrice}</span>
                        <span>{formatCurrency(orderData?.data?.total_price)}</span>
                    </div>
                    <div className="bill-detail-content">
                        <span className="gray-1-color">{t.text.totalDisc}</span>
                        <span className="red-color">{formatCurrency(orderData?.data?.total_discount)}</span>
                    </div>
                    <div className="bill-detail-content">
                        <span className="gray-1-color">{t.text.platformFee}</span>
                        <span>{formatCurrency(orderData?.data?.platform_fee)}</span>
                    </div>
                    {
                        orderData?.data?.takeaway_charge > 0 &&
                        <div className="bill-detail-content">
                            <span className="gray-1-color">{t.text.takeAwayCharge}</span>
                            <span>{formatCurrency(orderData?.data?.takeaway_charge)}</span>
                        </div>
                    }
                    {/* <div className="bill-detail-content">
                        <span className="gray-1-color">{t.text.parkingFee}</span>
                        <span>{formatCurrency(orderData?.data?.parking_fee)}</span>
                    </div> */}
                    <div className="bill-detail-content">
                        <span>{t.text.deliveryFee}</span>
                        <span>{formatCurrency(orderData?.data?.delivery_price + orderData?.data?.parking_fee)}</span>
                    </div>
                    <div className="bill-detail-content to-pay">
                        <span>{t.text.toBePaid}</span>
                        <span>{formatCurrency((orderData?.data?.total_price - orderData?.data?.total_discount) + orderData?.data?.delivery_price + orderData?.data?.platform_fee + orderData?.data?.parking_fee + orderData?.data?.takeaway_charge)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Receipt