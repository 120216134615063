import React from 'react'
import ItemsInCart from './ItemsInCart'

import { Swiper, SwiperSlide } from 'swiper/react';

import {
    BottomNav,
    BottomConfirm
} from "../../components/common";

import { categoryItemIsShowIsNotAvailable, formatCurrency } from '../../../miscellaneous/helpers/utils';

import '../Cart.css'
import { t } from '../../../miscellaneous/language';

import MerchantDetailItem from '../../Merchant/MerchantCart'
import MerchantDetailDiscountItem from '../../Discount/MerchantDetailDiscountItem'
import MerchantDetailDiscountContainer from '../../Discount/MerchantDetailDiscountContainer';
import { useHistory } from 'react-router-dom';

const CartDetail = ({ cart, deliveryFee, totalPrice, totalDiscount, handleOrderNotes, platformFee, parkingFee, merchant }) => {
    const history = useHistory();

    // console.log(deliveryFee);
    // console.log(cart, 'ini adalah cart');

    const SwiperBreakpoints = {
        500: {
            slidesPerView: 3,
            // spaceBetween: 9,
        },
        425: {
            slidesPerView: 3,
            // spaceBetween: 9
        },
        375: {
            slidesPerView: 2,
            // spaceBetween: 9
        },
        320: {
            slidesPerView: 2,
            // spaceBetween: 9
        },
        280: {
            slidesPerView: 2,
            // spaceBetween: 9
        }
    }

    const handleDetailClick = (item, catChoosed, type, merchId)=> {
        history.push({
            pathname: '/merchant/'+ merchId,
            state:{
                item, catChoosed, type
            }
        })
    }

    return (
        <div className="cart-detail-container">
            <div className="cart-detail-main-content">
                <div className="items-in-cart-space-top"></div>
                <div className="items-in-cart-container">
                    {!cart.items ? <span>{t.loading}</span> : cart.items?.map((cartItem, index) => (
                        <ItemsInCart key={'item' + index} items={cartItem} cart={cart} isNotAvailable={categoryItemIsShowIsNotAvailable({category: cartItem.item_category})} />
                    ))}
                </div>
                <div className="comment-input-container">
                    <input type="text" onChange={(e) => handleOrderNotes(e.target.value)} placeholder={t.placeholder.noteMerchant} />
                </div>
                <div className="bill-detail-wrapper">
                    {platformFee == null || (deliveryFee?.data?.result?.Price == null) ?
                        <div className="bill-detail-container">
                            {/* <div className="bill-detail">
                                <span>{t.text.billDetail}</span>
                            </div> */}
                            <div className="bill-detail-content loading">
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                            </div>
                            <div className="bill-detail-content loading red-color">
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                            </div>
                            <div className="bill-detail-content loading">
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                            </div>
                            <div className="bill-detail-content loading">
                                <span>&nbsp;</span>
                                <span>&nbsp;</span>
                            </div>
                            <div className="bill-detail-content loading to-pay">
                                <span>&nbsp;</span>
                                <span>&nbsp;</span> {/* REMOVE 10K (STATIC FEE) */}
                            </div>
                            <div className="bill-detail-content loading to-pay">
                                <span>&nbsp;</span>
                                <span>&nbsp;</span> {/* REMOVE 10K (STATIC FEE) */}
                            </div>
                        </div>
                        :
                        <div className="bill-detail-container">
                            {/* <div className="bill-detail">
                                <span>{t.text.billDetail}</span>
                            </div> */}
                            <div className="bill-detail-content">
                                <span>{t.text.totalPrice}</span>
                                {/* <span>{formatCurrency(totalPrice + totalDiscount)}</span> */}
                                <span>{formatCurrency(totalPrice)}</span>
                            </div>
                            {/* <div className="bill-detail-content red-color">
                                <span>{t.text.totalDisc}</span>
                                <span>{formatCurrency(totalDiscount)}</span>
                            </div> */}
                            <div className="bill-detail-content">
                                <span>{t.text.platformFee}</span>
                                <span>{formatCurrency(platformFee)}</span>
                            </div>
                            {/* <div className="bill-detail-content">
                                <span>{t.text.parkingFee}</span>
                                <span>{formatCurrency(parkingFee)}</span>
                            </div> */}
                            <div className="bill-detail-content">
                                <span>{t.text.deliveryFee}</span>
                                <span>{formatCurrency((deliveryFee?.data?.result?.Price ? deliveryFee?.data?.result?.Price : 0) + parkingFee)}</span>
                            </div>
                            {merchant.takeaway_charge > 0 && 
                            <div className="bill-detail-content">
                                <span>{t.text.takeAwayCharge}</span>
                                {/* put your value here */}
                                <span>{formatCurrency((merchant.takeaway_charge))}</span>
                            </div>
                            }
                            <div className="bill-detail-content to-pay">
                                <span>{t.text.toBePaid}</span>
                                <span>{formatCurrency((totalPrice + (deliveryFee?.data?.result?.Price ? deliveryFee?.data?.result?.Price : 0) + platformFee) + parkingFee + cart?.merchant?.takeaway_charge)}</span> {/* REMOVE 10K (STATIC FEE) */}
                            </div>
                        </div>
                    }
                    <MerchantDetailItem merchant_details={merchant} />
                    <MerchantDetailDiscountContainer detailFunction={handleDetailClick} items={cart?.merchant?.merchant_items} typeUI={'cartDetail'} />                    
                    {/* <BottomConfirm /> */}
                </div>
            </div>
        </div>
    )
}

export default CartDetail
