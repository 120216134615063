const En = {
    btn: {
        saveName: 'Simpan nama',
        yes: 'Ya',
        no: 'Tidak',
        add: 'Tambah',
        viewCart: 'Lihat troli saya',
        alertOk: 'Saya faham',
        confirmOrder: 'Sahkan perjalanan anda',
        shopping: `Mari membeli-belah!`,
        status: {
            orderStatus0: 'Segar semula status tempahan anda',
            orderStatus1: 'Semak status penghantaran',
            orderStatus9: 'Tempahan dari peniaga lain'
        }
    },
    text: {
        info: 'Nota',
        nonPartnerInfo: 'Peniaga ini tidak mempunyai rakan kongsi',
        saveName: 'Salam sejahtera. Sebelum meneruskan, sila masukkan nama anda...',
        itemitem: 'Item',
        item: 'item',
        items: 'item',
        empty: 'Habis dijual',
        orderList: 'Senarai tempahan',
        billDetail: 'Butiran tempahan',
        totalPrice: 'Jumlah harga',
        totalDisc: 'Jumlah diskaun',
        platformFee: 'Yuran platform',
        parkingFee: 'Bayaran letak kereta',
        deliveryFee: 'Yuran penghantaran dan layanan',
        takeAwayCharge: "Caj bawa pulang",
        unavailable: "Tidak tersedia",
        toBePaid: 'Untuk dibayar',
        emptyCart: 'Troli beli-belah anda masih kosong :(',
        orderPlaced: {
            title: 'Tempahan berjaya dilakukan',
            desc: 'Menunggu peniaga mengesahkan tempahan anda'
        },
        driverFound: {
            title: 'Pemandu dijumpai',
            desc: 'Terima kasih kerana menggunakan perkhidmatan Maxim'
        },
        driverResto: {
            title: 'pemandu di tempat peniaga',
            desc: 'Pemandu telah tiba di tempat peniaga'
        },
        merchantCancel: {
            title: 'Tempahan anda dibatalkan',
            desc: 'Peniaga membatalkan tempahan anda'
        },
        merchantconfirm: {
            title: 'Tempahan anda disahkan',
            desc: 'Peniaga mengesahkan tempahan anda'
        },
        itemPickedUp: {
            title: 'tempahan anda sedang dihantar',
            desc: 'Tempahan anda sedang dijalankan'
        },
        completeStatus: {
            title: 'Tempahan anda sedang dilengkapkan',
            desc: 'Terima kasih kerana menggunakan perkhidmatan Maxim'
        },
        userCancel: {
            title: 'Tempahan anda dibatalkan',
            desc: 'Pengguna membatalkan tempahan anda'
        },
        buyerName: 'Nama pembeli',
        receiveAt: 'Diterima pada',
        status: 'Status',
        paymentType: 'Jenis pembayaran',
        cash: 'Tunai',
        note: 'Nota: ',
        noNote: 'Tiada nota',
        noteMerchant: 'Nota untuk peniaga',
        MerchantDetailDiscountContainer: { recommend: 'Disyorkan untuk anda' },
        AddToCart: 'Tambah ke troli',
        deliverTo: 'Dihantar kepada',
        merchantName: 'Nama Peniaga',
        deliveryAddress: 'Alamat Penghantaran',
        phoneNumber: 'Nombor telefon',
        merchantAddress: 'Alamat Penghantaran Peniaga'
    },
    label: { user_name: 'Nama anda: ' },
    placeholder: {
        name: 'Sila masukkan nama anda',
        searchHome: 'Carian untuk peniaga atau item ...',
        searchCategory: 'Carian untuk peniaga',
        noteItemDetail: 'Tulis sebarang butiran khusus di sini',
        searchItem: 'Carian untuk item ...',
        noteMerchant: 'Tuliskan komen atau cadangan anda untuk peniaga'
    },
    alert: {
        orderCancel: 'Tempahan ini dibatalkan',
        nonPartner: 'Bukan rakan kongsi',
        partnerPlus: 'Kos tempahan adalah anggaran dan mungkin berubah. Sila minta pemandu memberikan resit sebagai rujukan untuk pembayaran produk.',
        noteNonPartner: 'Peniaga ini bukan rakan kongsi rasmi Maxim, ketersediaan produk dan ketepatan harga mungkin berubah dan berbeza daripada keadaan sebenar. Teruskan?',
        cart: 'Troli',
        cartEmpty: 'Hanya tinggal satu kuantiti untuk item/menu ini dalam troli anda, kuantiti berkurangan bermakna mengeluarkan barang dari troli anda.. Teruskan?',
        alert: 'Amaran',
        noteProcessOrder: 'Pelayan sedang memproses permintaan anda, sila tunggu... Jika proses mengambil masa yang lama, sila mulakan semula aplikasi anda atau semak sambungan internet anda.',
        confirmOrder: 'Sahkan tempahan',
        noteConfirmOrder: 'Adakah anda pasti mahu meletakkan tempahan? Sila semak semula tempahan sebelum mengesahkannya.',
        noteCountDeliveryPrice: 'Ralat berlaku ketika mengira kos penghantara, sila mula semula aplikasi untuk meneruskannya.',
        noteDeliveryPrice: 'Ralat berlaku semasa mendapatkan semula lokasi anda untuk mengira kos penghantaran, sila mulakan semula aplikasi dan pastikan GPS anda dihidupkan untuk meneruskan.',
        notePlatformPrice: 'Ralat berlaku semasa mendapatkan semula yuran platform, sila mulakan semula aplikasi untuk meneruskan.',
        noteNonPartnerReceipt: 'Peniaga ini bukan rakan kongsi Maxim yang rasmi, ketersediaan item dan harga yang disenaraikan mungkin berbeza-beza. Sila buat pembayaran mengikut harga yang tertera pada resit.',
        merchantClose: 'Peniaga telah tutup!'
    },
    helmet: {
        title: {
            start: 'Makanan&Barangan - Mula',
            Home: 'Makanan&Barangan',
            Category: 'Makanan&Barangan - Kategori peniaga',
            History: 'Makanan&Barangan - Sejarah',
            DetailCart: 'Makanan&Barangan - Butiran troli',
            afterOrder: 'Makanan&Barangan - Selepas Tempahan',
            HistoryDetail: 'Makanan&Barangan - Butiran sejarah'
        },
        desc: {
            start: 'Mula',
            Home: 'Makanan&Barangan',
            Category: 'Senarai peniaga mengikut kategori',
            History: 'Senarai transaksi anda sebelum ini',
            DetailCart: 'Butiran item dalam troli anda',
            afterOrder: 'Tempahan saya',
            HistoryDetail: 'Butiran sejarah'
        }
    },
    topNav: { title: 'Makanan&Barangan' },
    backTopNav: { History: 'Sejarah' },
    loading: 'Memuatkan…',
    data: {
        noDataMerchant: 'Peniaga tidak dijumpai',
        DataMerchant: 'Peniaga dijumpai',
        notFound: 'Tidak dijumpai'
    },
    bottomNav: {
        Home: 'Utama',
        Explore: 'Terokai',
        Promo: 'Promosi',
        History: 'Sejarah',
        Cart: 'Troli'
    },
    status: {
        Open: 'Buka',
        Close: 'Tutup',
        case0: 'Menunggu kelulusan kedai',
        case1: 'Tempahan diluluskan oleh kedai',
        case2: 'Pemandu Maxim menghantar tempahan',
        case3: 'Pemandu Maxim ditugaskan untuk tempahan',
        case4: 'Pemandu sedang menunggu',
        case11: 'Tempahan lengkap',
        case9: 'Tempahan dibatalkan',
        caseNotFound: 'Status tempahan tidak dijumpai',
        orderStatus0: 'Sedang menunggu',
        orderStatus1: 'Disahkan oleh peniaga',
        orderStatus2: 'Di ambil oleh Pemandu',
        orderStatus3: 'Pemandu ditetapkan',
        orderStatus4: 'Pemandu sedang menunggu',
        orderStatus9: 'Dibatalkan oleh peniaga',
        orderStatus11: 'Lengkap',
        orderStatus99: 'Dibatalkan oleh pengguna',
        orderStatusNotFound: 'Status tidak dijumpai',
        itemNotFound: 'Tiada item dijumpai dalam kategori ini'
    },
    receiveAt: { statusAccepted: 'Belum terima lagi' },
    bottomConfirm: {
        payment: {
            title: 'Kaedah pembayaran digital',
            title2: 'Kaedah pembayaran lain',
            cash: 'Tunai',
            noncash: 'Bukan tunai',
            paywith: 'Bayar dengan',
            soon: 'Tidak lama lagi'
        },
        btn: { confirm: 'Sahkan dan teruskan tempahan' }
    },
    itemDetails: { note: 'Nota untuk peniaga' },
    addon: {
        optional: 'Pilihan',
        qty: 'Kuantiti',
        additm: 'Tambah item',
        edititm: 'Kemas kini item',
        max: 'Pilih maksimum',
        edit: 'Ubah',
        require: 'Diperlukan',
        selectrequire: 'Pilih minimum',
        price: 'Harga',
        addcustome: 'Tambah satu lagi pelanggan',
        free: 'Percuma'
    },
    promo: { listMerchants: 'Senarai pedagang' }
};
export default En;