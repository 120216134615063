import axios from 'axios';

const baseURL = 'https://idmarket-api.taximaxim.com/api/v1/order'; //prod
// const baseURL = 'http://localhost:8001/api/v1/order'; //dev

export const sendOrderAPI = async (userID, lat, lon, city, host, name, addressName, total_item, total_price, total_discount, delivery_price, total, notes, merchants_id, order_items, platform_fee, parking_fee, takeaway_charge) => {
    console.log(takeaway_charge, 'test send order api');
    const res = await axios.post(baseURL, {
        userID,
        lat,
        lon,
        city,
        host,
        name,
        addressName, 
        total_item,
        total_price,
        total_discount,
        delivery_price,
        total,
        notes,
        merchants_id,
        order_items,
        platform_fee: platform_fee,
        parking_fee: parking_fee,
        takeaway_charge: takeaway_charge,
        origin: 'id'
    });

    return res.data;
}

export const getOrderDetails = async (userID, lat, lon, city, host, name, addressName, orderId) => {
    const res = await axios.get(baseURL + '/' + orderId, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            addressName, 
            name, 
            origin: 'id'
        }
    });

    return res.data;
}