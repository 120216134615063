const En = {
    btn: {
        saveName: 'Save name' ,
        yes: 'Yes',
        no: 'No',
        add: 'Add',
        viewCart: 'View my cart',
        alertOk: 'I understand',
        confirmOrder:'Confirm your order',
        shopping: `Let's go shopping!`,
        status: {
            orderStatus0:'Refresh order status',
            orderStatus1:'Check delivery status',
            orderStatus9:'Order from other merchants'
        }
    },
    text: {
        info:'Note',
        nonPartnerInfo: 'This merchant is not a partner',        
        saveName: 'Hello. Before continuing, please enter your name...',
        itemitem: 'Items',
        item: 'item',
        items: 'items',
        empty: 'Sold out',
        orderList: 'Order list',
        billDetail: 'Bill detail',
        totalPrice: 'Total price',
        totalDisc: 'Total discount',
        platformFee: 'Platform fee',
        parkingFee: 'Parking fee',
        deliveryFee: 'Delivery and Handling fee',
        takeAwayCharge: "Takeaway charge",
        unavailable: "Unavailable",
        toBePaid: 'To be paid',
        emptyCart: 'Your shopping cart is still empty :(',
        orderPlaced: {
            title: 'Order placed successfully',
            desc: 'Waiting for the merchant to confirm your order'
        },
        driverFound: {
            title: 'Found the driver',
            desc: 'Thank you for using the Maxim service'
        },     
        driverResto: {
            title: 'driver at the merchant place',
            desc: 'Driver has arrived at the merchant place'
        },        
        merchantCancel: {
            title: 'Your order is canceled',
            desc: 'Merchant canceled your order'
        },
        merchantconfirm: {
            title: 'Your order is confirmed',
            desc: 'Merchant confirmed your order'
        },
        itemPickedUp: {
            title: 'your order is being shipped',
            desc: 'Your order is in progress'
        },
        completeStatus: {
            title: 'Your order is complete',
            desc: 'Thank you for using the Maxim service'
        },
        userCancel: {
            title: 'Your order is canceled',
            desc: 'User canceled your order'
        },
        buyerName: 'Buyer name',
        receiveAt: 'Receive at',
        status: 'Status',
        paymentType: 'Payment type',
        cash: 'Cash',
        note: 'Note: ',
        noNote: 'No note',
        noteMerchant: 'Note for merchant',
        MerchantDetailDiscountContainer: {
            recommend: 'Recommended for you',
        },
        AddToCart: 'Add to cart',
        deliverTo: 'Deliver to',
        merchantName: 'Merchant`s Name',
        deliveryAddress: 'Delivery Address',
        phoneNumber: 'Phone number',
        merchantAddress: 'Merchant`s Address'
    },
    label:{
        user_name: 'Your name: '
    },
    placeholder: {
        name: 'Please enter your name',
        searchHome: 'Search for merchant or item ...',
        searchCategory: "Search for merchant",
        noteItemDetail: 'Write any specific details here',
        searchItem: 'Search for item ...',
        noteMerchant: "Write your comment or suggestion for merchant" 
    },
    alert:{
        orderCancel: 'This order is cancelled',
        nonPartner: 'Not a partner',
        partnerPlus:
      "The order price is an estimate and may change. Please ask the driver to provide the receipt for product payment reference.",
        noteNonPartner: 'This merchant is not an official Maxim partner, product availability and price accuracy may change and differ from actual conditions. Continue?',
        cart: 'Cart',
        cartEmpty: 'There is only one quantity left for this item/menu in your cart, decreasing quantity means removing items from your cart.. Continue?',
        alert: 'Alert',
        noteProcessOrder: 'The server is processing your request, please wait... If the process takes too long, please restart your app or check your Internet connection.',
        confirmOrder: 'Confirm order',
        noteConfirmOrder: 'Are you sure you want to place your order? Please check the order again before confirming it.',
        noteCountDeliveryPrice: 'An error occurred while calculating the delivery price, please restart the app to continue.',
        noteDeliveryPrice: 'An error occurred while retrieving your location to calculate the delivery price, please restart the app and make sure your GPS is on to continue.',
        notePlatformPrice: 'An error occurred while retrieving the platform fee, please restart the app to continue.',
        noteNonPartnerReceipt: 'This merchant is not an official Maxim partner, item availability and prices listed may vary. Please make a payment according to the price stated on the receipt.',
        merchantClose: 'Merchant is closed!'
    },
    helmet: {
        title:{
            start: 'Foods&Goods - Start',
            Home: "Foods&Goods",
            Category: "Foods&Goods - Merchant category",
            History: 'Foods&Goods - History',
            DetailCart: "Foods&Goods - Cart details",
            afterOrder: 'Foods&Goods - After Order',
            HistoryDetail: 'Foods&Goods - History details',
        },
        desc: {
            start:'Start',
            Home: "Foods&Goods",
            Category: "Merchant list by category",
            History: 'List of your previous transactions',
            DetailCart: "Details of items in your cart",
            afterOrder: 'My order',
            HistoryDetail: 'History details',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: 'History'
    },
    loading: 'Loading…',
    data: {
        noDataMerchant: 'No merchant found', 
        DataMerchant: 'Merchant found', 
        notFound: 'Not found'
    },
    bottomNav: {
        Home: 'Home',
        Explore: 'Explore',
        Promo: 'Promo',
        History: 'History',
        Cart:'Cart'
    },    
    status: {
        Open: 'Open',
        Close: 'Closed',
        case0: 'Waiting for the store’s approval',
        case1: 'Order approved by the store',
        case2: 'Maxim driver delivered the order',
        case3: 'Maxim driver assigned to the order',
        case4: 'Driver is waiting',
        case11: 'Order complete',
        case9: 'Order canceled',
        caseNotFound: 'Order status not found',
        orderStatus0 : 'Waiting',
        orderStatus1 : 'Approved by merchant',
        orderStatus2 : 'Picked up by Driver',
        orderStatus3 : 'Driver assigned',
        orderStatus4 : 'Driver is waiting',
        orderStatus9 : 'Canceled by merchant',
        orderStatus11 : 'Complete',
        orderStatus99 : 'Canceled by user',
        orderStatusNotFound : 'Status not found',
        itemNotFound: 'No items found in this category',
    },
    receiveAt: {
        statusAccepted: "Not yet received"
    },
    bottomConfirm: {
        payment: {
            title: 'Digital payment method(s)', 
            title2: 'Other payment method(s)',
            cash: 'Cash',
            noncash: 'Non-cash',
            paywith: 'Pay with',
            soon: 'Soon'
        },
        btn: {
            confirm: 'Confirm and proceed to order',
        }
    },
    itemDetails: {
        note: 'Note for the merchant',
    },
    addon: {
        optional: "Optional",
        qty: "Quantity",
        additm: "Add item",
        edititm: "Update item",
        max: "Select max",
        edit: "Edit",
        require: 'Require',
        selectrequire: "Minimum select",
        price: "Price",
        addcustome: "Add another custome",
        free:'Free'
    },
    promo: {
        listMerchants: "List of merchants",
    }
}

export default En